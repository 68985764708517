.jdi_recommend_wrapper {
  width: 1264px;
  margin: 32px auto;
  text-align: center;

  .loading {
    height: 76px;
  }
}

.tab_headers {
  overflow: auto;
  padding-bottom: 4px;
  &:hover{
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.14);
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.28);
      cursor: pointer;
      border-radius: 4px;
    }
  }
}

/* WebKit滚动条样式 */
.tab_headers::-webkit-scrollbar {
  height: 4px;
}

.tab_headers::-webkit-scrollbar-track {
  // background: #fff;
}

.tab_headers::-webkit-scrollbar-thumb {
  background-color: transparent;
  cursor: pointer;
  border-radius: 4px;
}
.tab_header_wrapper {
  width: fit-content;
  display: flex;
  padding: 0;
  margin: 0;
  height: 46px;
}

.tab_header {
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  margin-right: 28px;
  color: #505259;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  white-space: nowrap;
}

.tab_header:hover {
  color: #1A1A1A;
}

.tab_header.active {
  font-weight: 700;
  color: #1A1A1A;
}

.active {
  &::after {
    content: '';
    position: absolute;
    width: 36px;
    left: 50%;
    bottom: 0;
    height: 3px;
    border-radius: 1px;
    background-color: #1A1A1A;
    transition: left 0.3s ease, width 0.3s ease;
    transform: translateX(-50%);
  }
}

.more_btn {
  padding: 10px 16px 10px 16px;
  border-radius: 4px;
  border: 1px solid #E0E1E5;
  background-color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 40px;

  i {
    display: inline-block;
    width: 6px;
    height: 10px;
    background: no-repeat center;
    background-size: 100%;
    vertical-align: middle;
    transform: rotate(90deg);
    margin-left: 10px;
  }
}

.reached {
  padding: 16px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #C2C4CC;
}