
.product-list {
  display: flex;
  flex-wrap: wrap;
  padding: 32px 0;
  gap: 16px;
  animation: fadeIn 0.3s ease-in-out;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
