.pageError {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0;
  line-height: 0;
  text-align: center;
  .row{
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon{
      width: 50px;
      height: 50px;
      margin-right: 8px;
    }
    .pageErrorText {
      width: 165px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #C2C4CC;
    }
  }
  .column{
    display: flex;
    align-items: center;
    flex-direction: column;
    .icon{
      width: 120px;
      height: 120px;
    }
    .pageErrorText {
      width: 165px;
      margin: 12px 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #C2C4CC;
    }
  }
  .btn{
    margin-top: 8px;
  }
}