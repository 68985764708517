.errorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 120px;
    height: 120px;
    margin-bottom: 12px;
  }
  .showMessage {
    color: rgba(194, 196, 204, 1);
    font-size: 14px;
    margin-bottom: 12px;
  }
  .btn {
    padding: 6px 12px;
    color: rgba(26, 26, 26, 1);
    border-radius: 4px;
    border: 1px solid rgba(136, 139, 148, 1);
    cursor: pointer;
    z-index: 66;
    font-size: 12px;
  }
}
