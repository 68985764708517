.videoPlayer_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  .videoDom {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .videoPlayer_mask {
    .videoPlayIcon {
      position: absolute;
      transition: opacity 0.3s ease-in-out;
      color: #fff;
      cursor: pointer;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .videoPay {
      position: absolute;
      top: 8px;
      left: 8px;
      cursor: pointer;
    }
  }
  .videoPlayer_mask_wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #1a1a1a;
    z-index: 9998;
    .contentWrap {
      width: 80vw;
      height: 70vh;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      .videoDom {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        z-index: 2;
      }
      .videoPayBig {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
        z-index: 3;
      }
      .videoPayBigShow {
        opacity: 0;
        z-index: 1;
      }
    }
    .consoleWrap {
      width: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 8px 0;
      .consoleIcon {
        cursor: pointer;
        width: 32px;
        height: 32px;
        background-color: #505259;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: #888b94;
        }
      }
      .volumeIcon {
        position: relative;
        .volumeSlider {
          position: absolute;
          bottom: 127%;
          left: 50%;
          z-index: 19;
          transform: translateX(-50%);
          width: 32px;
          height: 120px;
          border-radius: 16px;
          // background: rgba(255, 255, 255, 0.3);
          background: rgba(0, 0, 0, 0.5);
          padding: 16px 0 8px 0;
          // opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }
        .volumeLevel {
          position: absolute;
          bottom: 8px;
          left: 50%;
          transform: translateX(-50%);
          background: #fff;
          width: 10px;
          border-radius: 16px;
        }
      }
      .consoleWrapCenter {
        display: flex;
        justify-content: center;
        align-items: center;
        .duration,
        .currentTime {
          color: #fff;
          font-size: 14px;
          line-height: 20px;
          margin: 0 16px;
        }
        .progressBarWrap {
          position: relative;
          width: 650px;
          display: flex;
          justify-content: center;
          align-items: center;
          .progressBar {
            width: 100%;
            height: 10px;
            background: rgba(255, 255, 255, 0.3);
            cursor: pointer;
            position: relative;
            border-radius: 4px;
            overflow: hidden;
          }
          .progressFilled {
            height: 100%;
            background-color: #fff;
            position: absolute;
            border-radius: 4px;
          }
        }
      }
      .consoleWrap_left {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }
    }
    .textWrap {
      width: 80%;
      color: #fff;
      .textWrap_title {
        padding: 8px 0;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: 20px;
        padding: 4px 0;
      }
      .textWrap_content {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .closeIcon {
      position: absolute;
      top: 5%;
      right: 5%;
      cursor: pointer;
    }
  }
}
