.view_image {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  // background-color: rgba(0, 0, 0, 0.8);
  background-color: #1a1a1a;
  opacity: 1;
  z-index: 9998;
  transition: all 0.3s ease-in-out;
  overflow-y: hidden;
  .mack {
    width: 1440px;
    height: 1000px;
    position: absolute;
    z-index: 9998;
    top: 50%;
    left: 50%;
    transform: translate(-53%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // overflow-y: hidden;
    img {
      width: 100%;
      height: 70vh;
      object-fit: contain;
    }
    .header {
      width: 50vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      color: #fff;
      font-size: 16px;
    }
    .content {
      width: 50vw;
      color: #fff;
      white-space: pre-wrap;
      word-break: break-all;
      font-size: 14px;
      line-height: 20px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .children {
      color: #fff;
    }
  }
  .pageLeft {
    width: 40px;
    height: 40px;
    position: fixed;
    z-index: 9999;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #505259;
    &:hover {
      background-color: #888b94;
    }
    left: 3.6%;
    transform: translateX(-50%);
  }
  .pageRight {
    width: 40px;
    height: 40px;
    position: fixed;
    z-index: 9999;
    top: 50%;
    border-radius: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #505259;
    &:hover {
      background-color: #888b94;
    }
    right: 3.6%;
    transform: translateX(-50%);
  }
  .closeIcon {
    position: absolute;
    z-index: 9999;
    top: 5%;
    right: 5%;
    cursor: pointer;
  }
}
.view_image_wrapper {
  opacity: 1;
}
