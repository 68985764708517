.item{
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    img{
        width: 44px;
        height: 44px;
    }
    .container{
        width: 283px;
        height: 44px;
        .top-conent{
            margin-top: 2px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .item-title{
                max-width: calc(100vw - 160px);
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                overflow: hidden;
                white-space: nowrap;
                display: inline-block;
                text-overflow: ellipsis;
            }
            .time{
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                white-space: nowrap;
                color: var(--sys-Text-Tertiary);
            }
        }
        .bottom-content{
            margin-top: 6px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .width-limit{
                max-width: 248px;
            }
            .content{
                // max-width: 248px;
                font-size: 12px;
                line-height: 16px;
                font-style: normal;
                text-align: left;
                overflow: hidden;
                white-space: nowrap;
                display: inline-block;
                text-overflow: ellipsis;
                color: var(--sys-Text-Tertiary);
            }
            .num {
              min-width: 16px;
              height: 16px;
              border-radius: 100px;
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;
              background: var(--sys-color-Primary700);
              span{
                padding: 4px;
                font-size: 10px;
                font-weight: 700;
                line-height: 14px;
                text-align: center;
                color: var(--sys-background-White);
              }
            }
            .dot {
              width: 6px;
              height: 6px;
              border-radius: 100px;
              background: var(--sys-color-Primary700);
            }
        }
    }
}