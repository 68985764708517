.floatingBtn {
  width: 68px;
  min-height: 48px;
  padding: 8px 6px;
  border: 1px solid #0000000F;
  border-right: none;
  border-radius: 8px 0 0 8px;
  background-color: #fff;
  color: #1A1A1A;
  cursor: pointer;
  display: block;
  margin-bottom: 4px;
  font-size: 12px;
}

.floatingBtn:hover {
  color:#990915;
  .hoverIcon {
    svg,
    path{
      stroke: #990915;
    };
    path{
      stroke: #990915;
    };
    path{
      fill: #F7F8FC;
      stroke: #990915;
    };
    path{
      fill: #F7F8FC;
      stroke: #990915;
    };
    circle{
      fill: #990915;
    };
  }
}

.heightNormal {
  height: 60px;
}

.heightLarger {
  height: 72px;
}

.iconTextWrap {
  align-items: center;
  display: flex;
  justify-content: center;
}

.emptyBox {
  width: 67px;
  min-height: 51px;
  border: 1px solid transparent;
  border-right: none;
  font-size: 12px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.messageIcon {
  top: 6px;
  right: 13px;
  position: absolute;
  min-width: 16px;
  height: 16px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: var(--sys-color-Primary700);
  span {
    padding: 4px;
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    text-align: center;
    color: var(--sys-background-White);
  }
}

.point {
  top: 8px;
  right: 22px;
  width: 6px;
  height: 6px;
  position: absolute;
  border-radius: 100px;
  background: var(--sys-color-Primary700);
  border: 1px solid var(--sys-background-White);
}