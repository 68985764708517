.container {
  padding: 16px;
  // width: 880px;
  background: #FFFFFF;
  border-radius: 8px;

  .draftTitle{
    // width: 375;
    height: 36px;
    gap: 10px;
    font-size: 16px;
    font-weight: 700;
    margin-top: 8px;
    padding-top: 4px;
    padding-right: 16px;
    padding-bottom: 4px;
    padding-left: 16px;
    display: flex;
    align-items: center;
    // color: var(--sys-background-Black);
  }

  .draftItemText{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--sys-State-Warning);
  }

  .flexRight{
    display: flex;
    justify-content: space-between;
    // width: 362px;
  }
  .flexWidthRight{
    display: flex;
    justify-content: space-between;
    // width: 460px;
  }
  .flexParentRight{
    display: flex;
    justify-content: space-between;
  }
  .flexHeaderRight{
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
  }
  .flexHeaderPerson{
    display: flex;
    height: 72px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: -16px;
    align-items: center;
    justify-content: space-between;
  }
  .title{
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #1A1A1A;
  }
  .info{
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: justified;
    color: #1A1A1A;
  }
  .titlePerson{
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #1A1A1A;
  }

  .noScrollbars {
    overflow-y: auto;
    max-height: 380px;
    min-height: 248px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .noScrollbars::-webkit-scrollbar {
    display: none;
  }
  .add{
    // width: 132px;
    height: 28px;
    padding: 5px 8px 5px 8px;
    gap: 4px;
    display: flex;
    // align-items: center;
    border-radius: 4px;
    border: 1px solid #CC0C1C;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #CC0C1C;
  }
  .flexLeft{
    display: flex;
    align-items: start;
    // width: 418px;
    height: 122px;
    padding: 12px;
    gap: 12px;
    border-radius: 4px;
    border: 1px solid #CC0C1C
  }
  .flexLeftGray{
    height: 122px;
    display: flex;
    align-items: start;
    // width: 418px;
    padding: 12px;
    gap: 12px;
    border-radius: 4px;
    border: 1px solid #E0E1E5
  }
  .box {
    // padding: 12px;
    background: #FFF;
    // width: 362px;
    .country{
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
      color: #000000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .phone{
      margin-left: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: #1A1A1A;
      white-space: nowrap;
    }
    .address{
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      height: 36px;
      text-align: left;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #505259;
      word-break: break-all; /* 允许在任意字符处换行 */   
    }
    .flexTopLeft{
      display: flex;
      align-items: center;
    }
    .flex{
      display: flex;
      align-items: center;
    }
    .addressActions {
      margin-left: 8px;
      cursor: pointer;
    }
    .flexTop{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .flexTop9{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 9px;
    }
    .setText{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      margin-left: 4px;
      color:#1A1A1A;
    }
    .copy{
      cursor: pointer;
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      margin-left: 5px;
      color: #1869F5;
    }
  }

  .personExpand {
    border: 1px solid rgb(224, 225, 229);
    width: 135px;
    height: 48px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 4px;
  }

  .expanded {
    cursor: pointer;
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #1A1A1A;
    transition: color 0.3s ease-in-out;

    &:hover {
        color: #CC0C1C;
    }

    .svgExpand,
    .svgExpanded {
        cursor: pointer;
        color: inherit;
        margin-left: 10px;
        top: -3px;
        position: relative;
        transition: transform 0.3s ease-in-out; // 添加过渡效果
    }

    .svgExpand {
        transform: rotate(0deg);
    }

    .svgExpand:hover,
    .svgExpanded:hover {
        color: #CC0C1C;
    }

    .svgExpanded {
        transform: rotate(180deg);
    }
  }
  .emptyBox{
    display: grid;
    margin-top: 70px;
    align-items: center;
    justify-items: center;
    .info{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 82px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        color: #C2C4CC;
    }
  }

  .errorPage {
    display: flex;
    justify-content: center;
    min-height: 330px;
    min-width: 880px;
    border-radius: 8px;
    background-color: #FFFFFF;
    margin: 16px 0;
    position: relative;
    text-align: center;
  }

  .errorPageH230 {
    display: flex;
    justify-content: center;
    max-height: 230px;
    min-height: 130px;
    min-width: 880px;
    border-radius: 8px;
    background-color: #FFFFFF;
    margin: 16px 0;
    position: relative;
    text-align: center;
  }

  .bottomFloat {
    height: 68px;
    background-color: #FFFFFF;
    bottom: 0;
    left: 0;
    display: flex;
    // padding-left: 16px;
    // padding-right: 16px;
    justify-content: space-between;
    align-items: center;
  }

  .closeButton {
    width: 62px;
    height: 32px;
    padding: 8px 16px 8px 16px;
    gap: 4px;
    border-radius: 4px;
    background: #CC0C1C;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #FFFFFF;
    text-transform: capitalize;    
  }
}