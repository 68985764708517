.mediaContainer {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;

  // 加载中
  .imageLoading {
    width: 100px;
    height: 100px;
    background: #f5f6fa;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;

    img {
      animation: spin 1s infinite linear;
    }

    @keyframes spin {
      0% {
        transform: rotate(360deg);
      }
      20% {
        transform: rotate(288deg);
      }
      40% {
        transform: rotate(216deg);
      }
      60% {
        transform: rotate(144deg);
      }
      80% {
        transform: rotate(72deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }

  .uploadImgWrapper {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.03);
    width: 100px;
    height: 100px;
    border-radius: 4px;
    .imgIcon {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    video {
      width: 100px;
      height: 100px;
      border-radius: 4px;
      object-fit: cover;
    }

    .imgWrapper {
      width: 100px;
      height: 100px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      &:hover {
        opacity: 1;
      }
      .viewIcon {
        width: 57px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        color: #1a1a1a;
        border-radius: 4px;
        cursor: pointer;
        background-color: #fff;
        position: relative;
        z-index: 1;
        border: 1px solid #00000000;
        &:hover {
          color: var(--sys-color-Primary800);
          border: 1px solid var(--sys-color-Primary800);
        }
      }
    }

    .imgReUpload {
      left: 50%;
      top: 50%;
      cursor: pointer;
      position: absolute;
      transform: translate(-50%, -50%);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      width: 69px;
      height: 28px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      border: 1px solid #E0E1E5;
      background-color: #FFFFFF;
      color: #1A1A1A;
      cursor: pointer;
      transition: all 0.3s ease;
  }

  .imgReUpload:hover {
      border-color: #FF3F4F;
      color: #FF3F4F;
  }

  }
  
  .upload {
    position: relative;
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0.5px solid rgba(0, 0, 0, 0.03);
    background: rgba(245, 246, 250, 1);
    border-radius: 4px;
    justify-content: center;
    font-size: 14px;
    color: rgba(136, 139, 148, 1);
    cursor: pointer;
    span {
      text-transform: none;
      line-height: 18px;
      margin-top: 8px;
      text-align: center;
    }
  }
  
  .upload:hover {
    .image{
      svg,
      path{
          fill: var(--sys-color-Primary800);
      };
      rect{
        stroke: var(--sys-color-Primary800);
      };
    }
    .video{
      svg,
      path{
          stroke: var(--sys-color-Primary800);
      };
    }
    span {
      color: var(--sys-color-Primary800);
    }
  }

  .delete {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 4px;
    right: 4px;
    z-index: 4;
    cursor: pointer;
  }

  .delete:hover {
      svg,
      path{
          fill: var(--sys-color-Primary800)
      }
  }
}