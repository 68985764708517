.container {
  // padding: 16px;
  // width: 880px;
  // background: #FFFFFF;
  // border-radius: 8px;

  .title{
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #1A1A1A;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .box{
    background-color: #FFFFFF;
    padding: 16px;
  }

  .country{
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .phone{
    margin-left: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #1A1A1A;
    white-space: nowrap;
  }
  .address{
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    // height: 36px;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #505259;
    word-break: break-all; /* 允许在任意字符处换行 */   
  }
  
  .flex{
    margin-top: 16px;
    display: flex;
    align-items: center;
  }

  .go {
    cursor: pointer;
    height: 18px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    line-height: 18px;
    text-align: left;
    color: #888B94;

    .svg {
        color: #888B94;
        margin-left: 7px;
        position: relative;
        transform: rotate(270deg);
        transition: transform 0.3s ease-in-out; // 添加过渡效果
    }
}
  
  .errorPage {
    display: flex;
    justify-content: center;
    min-height: 330px;
    min-width: 880px;
    border-radius: 8px;
    background-color: #FFFFFF;
    margin: 16px 0;
    position: relative;
    text-align: center;
  }
}