.dialogtitle__wrap {
    height: 50px;
    font-size: 16px;
    font-weight: bold;
}

.dialogcontent__wrap {
    width: 100%;

    .form__title {
        width: 100%;

        span {
            font-size: 14px;
            line-height: 18px;
            color: red;
        }

        em {
            font-weight: 700;
            font-size: 14px;
            line-height: 16.7px;
            font-style: normal;
        }
    }

    .form__radio {
        display: flex;
        flex-direction: column;
    }

    .dialog__footer {
        width: 100%;
        display: flex;
        justify-content: right;
        align-items: center;
        margin-top: 24px;
    }

    .upload__footer {
        font-size: 14px;
        line-height: 18px;
        color: #888B94;
        margin: 8px 0;
    }

    .input__wrap {
        position: relative;
        
        .input_text {
            position: absolute;
            right: 8px;
            bottom: 8px;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: #C2C4CC;

            .input_text_color {
                color: #1a1a1a;
            }
        }
    }

    .error_text {
        display: flex;
        align-items: left;
        color: #ff0f23;
        font-size: 12px;
    }
}